
.mobile-nav-close {
    display: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .navbar.active .mobile-nav-close {
    display: block;
  }
  
  /* Media query para pantallas más pequeñas (menos de 990px de ancho) */
  @media (max-width: 990px) {
    .navbar.active ul {
        display: flex;
        flex-direction: column;
        background-color: rgba(40, 58, 90, 0.9);
        position: fixed; /* Cambiado de absolute a fixed */
        top: 10;
        left: 10;
        width: 95%; /* Ocupa el 100% del ancho de la pantalla */
        height: 95vh; /* Ocupa el 100% de la altura de la pantalla */
        z-index: 999;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    
      .navbar.active li {
        width: 100%;
        text-align: left;
        margin: 0; /* Elimina el margen */
        padding: 10px;
      }
    
      .navbar {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
      }
    
      .navbar ul {
        display: none;
      }
    
      .navbar.active ul {
        display: flex;
        flex-direction: column;
        position: fixed; /* Cambiado de absolute a fixed */
        top: 0;
        left: 0;
        width: 100%; /* Ocupa el 100% del ancho de la pantalla */
        height: 100vh; /* Ocupa el 100% de la altura de la pantalla */
        z-index: 999;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    
      .navbar li {
        width: 100%;
        text-align: left;
      }
    
      .navbar.active li {
        padding: 10px;
      }
    
      .mobile-nav-toggle {
        display: block;
      }
  }